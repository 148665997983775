<template>
    <v-hover v-slot:default="{hover}">
        <v-card class="card off mx-2 mb-1" :class="{ 'card_hover' : hover}"
                :elevation="hover ? 4 : 2" style="cursor: pointer;">

            <v-card-text class="card-state pa-1" :class="state">
                <!--HEADER-->
                <v-row no-gutters justify="center">
                    <v-col cols="12" class="pb-0">
                        <span class="font-weight-bold"> {{item.date}}</span>
                    </v-col>
                    <v-col cols="12">
                        <v-divider class="gold lighten-1"></v-divider>
                    </v-col>
                </v-row>

                <!--DATA-->
                <v-row justify="center" align="center" class="" no-gutters>
                    <v-row align="center" no-gutters class="headline" style="font-size: 1.2em !important;">
                        <v-col v-bind="layout">
                            <v-row no-gutters align="center" >
                                <v-col cols="12" class="caption font-weight-bold">{{ g_i18_keys('savings') }}</v-col>
                                <v-col cols="12" class="gold--text">{{item.savings}}</v-col>
                            </v-row>
                        </v-col>
                        <v-col v-bind="layout">
                            <v-row no-gutters align="center">
                                <v-col cols="12" class="caption font-weight-bold">{{ g_i18_keys('payments') }}</v-col>
                                <v-col cols="12" class="gold--text">{{item.payments}}</v-col>
                            </v-row>
                        </v-col>
                        <v-col v-bind="layout">
                            <v-row no-gutters align="center" >
                                <v-col cols="12" class="caption font-weight-bold">{{ g_i18_keys('purchase_price') }}</v-col>
                                <v-col cols="12" class="gold--text">{{item.purchase_price}}</v-col>
                            </v-row>
                        </v-col>
                        <v-col v-bind="layout">
                            <v-row no-gutters align="center" >
                                <v-col cols="12" class="caption font-weight-bold">{{ g_i18_keys('advance_payments') }}</v-col>
                                <v-col cols="12" class="gold--text">{{item.advance_payments}}</v-col>
                            </v-row>
                        </v-col>
                        <v-col v-bind="layout">
                            <v-row no-gutters align="center" >
                                <v-col cols="12" class="caption font-weight-bold">{{ g_i18_keys('remaining') }}</v-col>
                                <v-col cols="12" class="gold--text">{{item.remaining}}</v-col>
                            </v-row>
                        </v-col>
                        <v-col v-bind="layout">
                            <v-row no-gutters align="center" >
                                <v-col cols="12" class="caption font-weight-bold">{{ g_i18_keys('total_remaining') }}</v-col>
                                <v-col cols="12" class="gold--text">{{item.total_remaining}}</v-col>
                            </v-row>
                        </v-col>
                        <v-col v-bind="layout">
                            <v-row no-gutters align="center" >
                                <v-col cols="12" class="caption font-weight-bold">{{ g_i18_keys('historical_remaining') }}</v-col>
                                <v-col cols="12" class="gold--text">{{item.historical_remaining}}</v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <!--KEYS-->
                    <v-col v-if="false" cols="12">
                        <v-row justify="center">
                            <v-col v-for="v in keys" :key="v.value" xl="0" md="0">
                                <v-row no-gutters>
                                    <span class="caption gold--text">{{ g_i18_keys(v.value) }}</span>
                                </v-row>
                                <v-row no-gutters>
                                    <span class="font-weight-bold">{{ item[v.value] }}</span>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="false" cols="12">
                        <span class="caption info--text">ITEM_DATA: {{item}}</span>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>
    </v-hover>
</template>

<script>
export default {
    name: "GoldPurchaseReportCard",
    props: {
        item: Object,
        keys: undefined,
        state: undefined
    },
    data: () => ({
        card: { cols: 6, xs: 6, sm: 4, md: 1, align: 'center', class: 'my-2 full-cols'}
    }),
    computed: {
        layout() {
            return this.card;
        }
    }
}
</script>